var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mb-2"},[_vm._v("Information du forfait")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nom du forfait","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nom du forfait","label-for":"transport-options"}},[_c('b-form-input',{attrs:{"id":"`option-name-%{option.index}`","state":errors.length > 0 ? false : null,"placeholder":"Escapade à Paris"},model:{value:(_vm.packageOption.name),callback:function ($$v) {_vm.$set(_vm.packageOption, "name", $$v)},expression:"packageOption.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(_vm.pcVoyageEnable)?_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Numéro de référence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de référence","label-for":"reference-number"}},[_c('b-form-input',{attrs:{"id":"`reference-number`","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.packageOption.reference_number),callback:function ($$v) {_vm.$set(_vm.packageOption, "reference_number", $$v)},expression:"packageOption.reference_number"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3936935633)})],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Image du forfait","rules":_vm.currentPackage.image_url === undefined ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image du forfait","label-for":"description-froup"}},[_c('b-form-file',{ref:"file-upload",attrs:{"placeholder":"Veuillez sélectionner une image...","state":errors.length > 0 ? false : null,"drop-placeholder":"Déposer l'image ici...","browse-text":"Parcourir"},on:{"change":_vm.uploadImageToPackage},model:{value:(_vm.images.file1),callback:function ($$v) {_vm.$set(_vm.images, "file1", $$v)},expression:"images.file1"}}),_c('small',{staticClass:"form-text text-muted"},[_vm._v("Poids maximal de l'image: 5 Mo")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-img',{staticClass:"mt-1",attrs:{"src":_vm.packageOption.image_url,"fluid":""}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mb-2"},[_vm._v("Paramètres du forfait")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Type d'occupation offert dans ce forfait","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type d'occupation offert dans ce forfait","label-for":"transport-options","state":errors.length > 0 ? false : null}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-2","name":"flavour-2"},model:{value:(_vm.packageOption.occupation_types),callback:function ($$v) {_vm.$set(_vm.packageOption, "occupation_types", $$v)},expression:"packageOption.occupation_types"}},[_c('b-form-checkbox',{attrs:{"value":{ type: 'single' }}},[_vm._v(" Simple ")]),_c('b-form-checkbox',{attrs:{"value":{ type: 'double' }}},[_vm._v(" Double ")]),_c('b-form-checkbox',{attrs:{"value":{ type: 'triple' }}},[_vm._v(" Triple ")]),_c('b-form-checkbox',{attrs:{"value":{ type: 'quadruple' }}},[_vm._v(" Quadruple ")])],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Enfants acceptés dans le groupe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Enfants acceptés dans le groupe","label-for":"Enfants acceptés dans le groupe","state":errors.length > 0 ? false : null}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"are_children_accepted-radios","value":true},model:{value:(_vm.packageOption.accept_children),callback:function ($$v) {_vm.$set(_vm.packageOption, "accept_children", $$v)},expression:"packageOption.accept_children"}},[_vm._v(" Oui ")]),_c('b-form-radio',{attrs:{"name":"are_children_accepted-radios","value":false},model:{value:(_vm.packageOption.accept_children),callback:function ($$v) {_vm.$set(_vm.packageOption, "accept_children", $$v)},expression:"packageOption.accept_children"}},[_vm._v(" Non ")])],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.publicUrlEnabled)?_c('b-form-group',{attrs:{"label":"Affichage du forfait","label-for":"Affichage du forfait"}},[_c('div',{staticClass:"mt-1"},[_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"is-rtl","switch":"","inline":""},model:{value:(_vm.packageOption.is_public),callback:function ($$v) {_vm.$set(_vm.packageOption, "is_public", $$v)},expression:"packageOption.is_public"}},[_vm._v(" Visible sur la page du groupe pour les clients externes ")])],1)]):_vm._e()],1)],1),(_vm.group.has_coach_transportation)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mt-2 mb-2"},[_vm._v(" Option de transport par navette/autocar associé au forfait ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Option de transport par navette/autocar","vid":"Option de transport par navette/autocar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Option de transport par navette/autocar","label-for":"flight-transportation","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"flight-transportation","options":_vm.coachTransportOptions,"clearable":false,"label":"label"},model:{value:(_vm.packageCoachTransportOption),callback:function ($$v) {_vm.packageCoachTransportOption=$$v},expression:"packageCoachTransportOption"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3273150979)})],1)],1):_vm._e(),(_vm.group.has_flight_transportation)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mt-2 mb-2"},[_vm._v(" Option de transport aérien associé au forfait ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Option de transport aérien","vid":"Option de transport aérien","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Option de transport aérien","label-for":"flight-transportation","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"flight-transportation","options":_vm.flightTransportOptions,"clearable":false,"label":"label"},model:{value:(_vm.packageFlightTransportOption),callback:function ($$v) {_vm.packageFlightTransportOption=$$v},expression:"packageFlightTransportOption"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,594316721)})],1)],1):_vm._e(),(_vm.group.has_accommodation)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"section-label mx-0 mt-2 mb-2"},[_vm._v(" Hébergement associé au forfait ")])])],1),_vm._l((_vm.packageOption.room_ids),function(value,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Hébergement associé","rules":"required","vid":'Hébergement associé' + index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Hébergement associé","label-for":"accommodation-options","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'Hébergement associé' + index,"options":_vm.accommodationOptions,"label":"name","reduce":function (packageOption) { return packageOption.id; }},model:{value:(_vm.packageOption.room_ids[index].accommodation),callback:function ($$v) {_vm.$set(_vm.packageOption.room_ids[index], "accommodation", $$v)},expression:"packageOption.room_ids[index].accommodation"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column justify-content-center",attrs:{"md":"1"}},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeAccommodationRoom(index)}}},[_c('feather-icon',{attrs:{"size":"13","icon":"TrashIcon"}})],1)],1)])],1)}),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.addAccommodationToPackage(_vm.packageOption)}}},[_vm._v(" + Ajouter un hébergement associé au forfait ")]),_c('validation-provider',{attrs:{"name":"hébergement associé","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.packageOption.room_ids),expression:"packageOption.room_ids"}],attrs:{"type":"hidden","state":errors.length > 0 ? false : null},domProps:{"value":(_vm.packageOption.room_ids)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.packageOption, "room_ids", $event.target.value)}}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_c('feather-icon',{staticClass:"text-danger align-middle mr-25",attrs:{"icon":"AlertCircleIcon"}}),_vm._v(" Il faut choisir un hébergement ")],1)]}}],null,false,3237958058)})],1)],1)],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }