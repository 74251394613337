<template>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >

        <div v-if="!hasDetails" class="mt-4">
          <b-card  title="Étape 1" header-tag="header" body-class="text-center" class="mx-auto" style="max-width: 80%; min-width: 60%">

            <b-card-text>
              <p>
                Ajouter les informations générales relatives à votre forfait.
              </p>
              <p>
                Associer une option de transport avec une ou des options d'hébergement.
              </p>
              <p>
                Indiquer le ou les types d'occupation offerts dans ce forfait.
              </p>

            </b-card-text>

            <b-button variant="primary"  @click="showPackageInfoModal">Ajouter</b-button>
          </b-card>
        </div>
        <b-card v-if="hasDetails"  header-tag="header" class="mt-2" >
          <template #header>
              <h3> Information du forfait </h3>
              <b-button variant="outline-primary" @click="showPackageInfoModal">Modifier</b-button>
          </template>
          <b-row class="mb-2">
          <b-col md="3">
            <b-carousel
              img-width="150"
              img-height="150"
              controls
              indicators

            >
              <b-carousel-slide :img-src="packageOption.image_url || require('@/assets/images/group/stock_plane_2.jpeg')">
              </b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col md="9">
          <b-row>
            <b-col lg="3" md="4" sm="4">
              <div>
                <h5 class="mb-75">
                  Nom du forfait
                </h5>
                <b-card-text>
                  {{packageOption.name }}
                </b-card-text>
              </div>
            </b-col>

            <b-col v-if="group.has_coach_transportation" lg="3" md="4" sm="4">
              <div>
                <h5 class="mb-75">
                  Option de transport par navette/autocar
                </h5>
                <b-card-text>
                  {{coachTransportOption}}
                </b-card-text>
              </div>
            </b-col>

            <b-col v-if="group.has_flight_transportation" lg="3" md="4" sm="4">
              <div>
                <h5 class="mb-75">
                  Option de transport aérien
                </h5>
                <b-card-text>
                  {{ flightTransportOption }}
                </b-card-text>
              </div>
            </b-col>

            <b-col lg="3" md="4" sm="4">
              <div>
                <h5 class="mb-75">
                  Enfants acceptés dans le groupe
                </h5>
                <b-card-text>
                  {{ packageOption.accept_children ? "Oui" : "Non" }}
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="3">
              <div>
                <h5 class="mb-75">
                  Type d'occupation offert
                </h5>
                <b-card-text>
                  <ul style="list-style: none; padding-inline-start: 2px">
                    <li v-for="(occupation) in packageOption.occupation_types" :key="occupation.id">
                      <span class="bullet bullet-sm mr-1 bullet-success"></span>
                      <small>{{occupation.type}}</small>
                    </li>
                  </ul>
                </b-card-text>
              </div>
            </b-col>
            <b-col v-if="group.has_accommodation" cols="3">
              <div>
                <h5 class="mb-75">
                  Hébergement associé au forfait
                </h5>
                <b-card-text>
                  <ul style="list-style: none; padding-inline-start: 2px">
                    <li v-for="(room) in packageOption.rooms" :key="room.id">
                      <span class="bullet bullet-sm mr-1 bullet-primary"></span>
                      <small>{{room.room_category}}</small>
                    </li>
                  </ul>
                </b-card-text>
              </div>
            </b-col>
            <b-col cols="3" v-if="tenantConfig.pcVoyageEnable">
              <div>
                <h5 class="mb-75">
                  Numéro de référence
                </h5>
                <b-card-text>
                  {{packageOption.reference_number }}
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="3" v-if="tenantConfig.publicUrlEnabled">
              <div>
                <h5 class="mb-75">
                  Affichage du forfait
                </h5>
                <b-card-text>
                  <span v-if="packageOption.is_public">
                    <feather-icon icon="EyeIcon" class="text-success"/>
                    Ce forfait est visible pour les clients externes
                  </span>
                  <span v-else>
                    <feather-icon icon="EyeIcon" class="text-grey" />
                    Ce forfait ne sera pas affiché pour les clients externes
                  </span>
                  </b-card-text>
              </div>
            </b-col>
          </b-row>
          </b-col>
          </b-row>

          <div class="text-right">
            <b-button variant="primary" class="ml-1" @click="$emit('next-tab')">Suivant</b-button>
          </div>

        </b-card>
        <GroupPackageInfoModal ref='package-info-modal' :currentPackage="packageOption" :transport-options="transportOptions" :accommodation-options="accommodationOptions" :pc-voyage-enable=tenantConfig.pcVoyageEnable @create="submitPackageInfo" @update="submitEditPackageInfo" />
      </b-col>
    </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BIconFileEarmarkEasel, BCarousel, BCarouselSlide  } from 'bootstrap-vue'
import GroupPackageInfoModal from '@/views/groups/group/GroupPackages/PackageModals/GroupPackageInfoModal'
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import {reactive, ref, computed} from "@vue/composition-api";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BCarousel, BCarouselSlide,
    GroupPackageInfoModal
  },
  props: {
    transportOptions: {
      type: Array
    },
    accommodationOptions: {
      type: Array
    },
    currentPackage: {
      type: Object
    },
    currentPackageId: {
      type: String
    },
    actionType: {
      type: String
    }
  },
  data() {
    return {
      hasPackageInfo: false,
      packageOption: {}
    }
  },
  setup() {
    let tenantConfig = reactive({
      pcVoyageEnable: false,
      publicUrlEnabled: false,
    });

    try {
      tenantConfig.pcVoyageEnable = store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;
      tenantConfig.publicUrlEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.public_url_enable;
    } catch (e) {
      console.error(e)
    }

  return {
    tenantConfig
  }
  },
  created: function () {
    try {
      if (this.currentPackage && this.currentPackage.id) {
        this.hasPackageInfo = true;
        this.packageOption = Object.assign({}, this.currentPackage);
      }
    } catch (error) {
    }

  },
  watch: {
    currentPackage: function(newValue, oldVal) { // watch it
      this.hasPackageInfo = true;
      this.packageOption = Object.assign({}, newValue);
    }
  },
  methods: {
    showAddPackageModal() {
      this.$refs['add-package-modal'].$children[0].show()
    },
    showEditPackageModal() {
      this.$refs['edit-package-modal'].$children[0].show()
    },
    showPackageInfoModal() {
      this.$refs['package-info-modal'].$children[0].show()
    },
    submitPackageInfo(payload) {
      this.hasPackageInfo = true;
      this.packageOption = this.getPackagePayload(payload);
      this.$emit('submitPackageInfo', payload)

    },
    submitEditPackageInfo(payload) {
      this.hasPackageInfo = true;
      this.packageOption = this.getPackagePayload(payload);
      this.$emit('submitUpdatePackageInfo', payload)

    },
    getPackageAcceptChildrenValue(value) {
      return value === 'true'
    },
    getPackagePayload(payload) {
      let occupation_types = [];

      payload.occupation_types.forEach(type => {
        occupation_types.push({
          type: type,
        })
      })

      return {
        name: payload.name,
        is_public: payload.is_public,
        reference_number: payload.reference_number,
        description: payload.description,
        accept_children: payload.accept_children,
        occupation_types: occupation_types,
        transportations: payload.transportations
      }
    },
  },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    coachTransportOption() {
      let transport = null
      this.currentPackage.transportations.forEach(x => {
        if(x.type === 'coach') {
          transport = x.name
        }
      })
      return transport ?? 'N/A'
    },
    flightTransportOption() {
      let transport = null
      this.currentPackage.transportations.forEach(x => {
        if(x.type === 'flight') {
          transport = x.name
        }
      })
      return transport ?? 'N/A'
    },
    hasDetails() {
      try{
        if (this.currentPackage.name || this.currentPackage.id) {
          return true;
        } else {
          return false;
        }
      } catch {
        return false
      }

    }
  }

}
</script>

<style>

</style>
