<template>
    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >
        <div v-if="!hasRules" class="mt-4">
          <b-card  title="Étape 2" header-tag="header" body-class="text-center" class="mx-auto" style="max-width: 80%; min-width: 60%">

            <b-card-text>
              Ajouter le nombre de voyageurs acceptés par type d'occupation.
            </b-card-text>

            <b-button variant="primary"  @click="showPackageRulesModal">Ajouter</b-button>
          </b-card>
        </div>
        <b-card v-if="hasRules"  header-tag="header" class="mt-2" >


          <template #header>
              <h3> Règles du forfait </h3>
              <b-button variant="outline-primary" @click="showPackageRulesModal">Modifier</b-button>
          </template>
          <b-row>

            <b-col cols="6" v-for="occupation in occupationTypes" :key="occupation.type" >
              <b-row class="mb-2">
                <b-col>
                  <h4 class="text-primary text-capitalize"> {{occupation.type}} </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col>

                  <h6 class="" >
                    Adulte Minimal
                  </h6>

                  <b-card-text>
                  {{occupation.adult_min}}
                  </b-card-text>

                </b-col>
                <b-col>

                  <h6 class="">
                    Maximum de voyageurs
                  </h6>
                  <b-card-text>
                    {{occupation.travelers_max}}

                  </b-card-text>

                </b-col>
              </b-row>
              <div class="mt-2" v-if="currentPackage.accept_children">
                <h6 class="">
                  Catégorie d'enfants supportée
                </h6>
                <b-card-text>
                  <ul style="list-style: none; padding-inline-start: 2px">
                    <li v-for="children_category in occupation.supported_children_types" :key="children_category">
                      <span class="bullet bullet-sm mr-1 bullet-success"></span>
                      <small>{{getChildrenCategory(children_category)}}</small>
                    </li>
                  </ul>
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <div class="text-right">
            <b-button variant="primary" class="ml-1" @click="$emit('next-tab')">Suivant</b-button>
          </div>
        </b-card>
        <group-package-rules-modal ref='package-rules-modal' @submitPackageRules="submitPackageRules" :current-package="currentPackage" />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
      </b-col>
    </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BIconFileEarmarkEasel } from 'bootstrap-vue'
import GroupPackageRulesModal from '@/views/groups/group/GroupPackages/PackageModals/GroupPackageRulesModal'
import groupCreationConfig from "@core/data/groupCreationConfig";

import store from '@/store/index'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupPackageRulesModal

  },
  props: {
    currentPackage: {
      type: Object
    },
    actionType: {
      type: String
    },
    hasPackageRules: {
      type: Boolean
    }
  },
  data() {
    return {
      groupCreationConfig
    }
  },
  methods: {
    showPackageRulesModal() {
      this.$refs['package-rules-modal'].$children[0].show()
    },
    showEditBasicInfoModal() {
      this.$refs['edit-basic-info-modal'].$children[0].show()
    },
    submitPackageRules(packageRules) {
      this.hasPackageRules = true;
      this.$emit('submitPackageRules', this.currentPackage)
    },
    displayCreationConfirmation(error) {
      let title = 'Groupe sauvegardé comme brouillon';
      let variant = 'success'

      if (error) {
        title = error.data.message
        variant = 'danger'
      }


      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'EditIcon',
          variant,
        },
      })
    },
    getChildrenCategory(type)
    {
      return this.groupCreationConfig.ageCategories.find(category => category.value === type).text
    },
  },
  computed: {
    occupationTypes() {
      return this.currentPackage.occupation_types ?? []
    },

    hasRules() {
      if (this.actionType == 'EDIT' || this.hasPackageRules) {
        return true;
      } else {
        return false;
      }
    },
    getOccupations() {
      try {
        return this.currentPackage.occupation_types
      } catch {
        return []
      }
    }
  }
}
</script>

<style>

</style>
