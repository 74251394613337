<template>
  <validation-observer ref="simpleRules">
    <b-form>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mb-2">Information du forfait</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" sm="12">
          <validation-provider
            #default="{ errors }"
            name="Nom du forfait"
            rules="required"
          >
            <b-form-group label="Nom du forfait" label-for="transport-options">
              <b-form-input
                id="`option-name-%{option.index}`"
                :state="errors.length > 0 ? false : null"
                v-model="packageOption.name"
                placeholder="Escapade à Paris"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6" sm="12" v-if="pcVoyageEnable">
          <validation-provider #default="{ errors }" name="Numéro de référence">
            <b-form-group
              label="Numéro de référence"
              label-for="reference-number"
            >
              <b-form-input
                id="`reference-number`"
                :state="errors.length > 0 ? false : null"
                v-model="packageOption.reference_number"
                placeholder=""
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Image du forfait"
            :rules="currentPackage.image_url === undefined ? 'required' : ''"
          >
            <b-form-group
              label="Image du forfait"
              label-for="description-froup"
            >
              <b-form-file
                ref="file-upload"
                v-model="images.file1"
                placeholder="Veuillez sélectionner une image..."
                :state="errors.length > 0 ? false : null"
                drop-placeholder="Déposer l'image ici..."
                browse-text="Parcourir"
                @change="uploadImageToPackage"
              ></b-form-file>
              <small class="form-text text-muted"
                >Poids maximal de l'image: 5 Mo</small
              >
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-img class="mt-1" :src="packageOption.image_url" fluid></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h1 class="section-label mx-0 mb-2">Paramètres du forfait</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Type d'occupation offert dans ce forfait"
            rules="required"
          >
            <b-form-group
              label="Type d'occupation offert dans ce forfait"
              label-for="transport-options"
              :state="errors.length > 0 ? false : null"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="packageOption.occupation_types"
                  name="flavour-2"
                >
                  <b-form-checkbox :value="{ type: 'single' }">
                    Simple
                  </b-form-checkbox>
                  <b-form-checkbox :value="{ type: 'double' }">
                    Double
                  </b-form-checkbox>
                  <b-form-checkbox :value="{ type: 'triple' }">
                    Triple
                  </b-form-checkbox>
                  <b-form-checkbox :value="{ type: 'quadruple' }">
                    Quadruple
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Enfants acceptés dans le groupe"
            rules="required"
          >
            <b-form-group
              label="Enfants acceptés dans le groupe"
              label-for="Enfants acceptés dans le groupe"
              :state="errors.length > 0 ? false : null"
            >
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="packageOption.accept_children"
                  :name="`are_children_accepted-radios`"
                  :value="true"
                >
                  Oui
                </b-form-radio>
                <b-form-radio
                  v-model="packageOption.accept_children"
                  :name="`are_children_accepted-radios`"
                  :value="false"
                >
                  Non
                </b-form-radio>
              </div>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            v-if="publicUrlEnabled"
            label="Affichage du forfait"
            label-for="Affichage du forfait"
          >
            <div class="mt-1">
              <b-form-checkbox
                v-model="packageOption.is_public"
                class="mr-0 mt-50"
                name="is-rtl"
                switch
                inline
              >
                Visible sur la page du groupe pour les clients externes
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="group.has_coach_transportation">
        <b-col md="12">
          <h1 class="section-label mx-0 mt-2 mb-2">
            Option de transport par navette/autocar associé au forfait
          </h1>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Option de transport par navette/autocar"
            vid="Option de transport par navette/autocar"
            rules="required"
          >
            <b-form-group
              label="Option de transport par navette/autocar"
              label-for="flight-transportation"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                v-model="packageCoachTransportOption"
                id="flight-transportation"
                :options="coachTransportOptions"
                :clearable="false"
                label="label"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row v-if="group.has_flight_transportation">
        <b-col md="12">
          <h1 class="section-label mx-0 mt-2 mb-2">
            Option de transport aérien associé au forfait
          </h1>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Option de transport aérien"
            vid="Option de transport aérien"
            rules="required"
          >
            <b-form-group
              label="Option de transport aérien"
              label-for="flight-transportation"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                v-model="packageFlightTransportOption"
                id="flight-transportation"
                :options="flightTransportOptions"
                :clearable="false"
                label="label"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <div v-if="group.has_accommodation">
        <b-row>
          <b-col md="12">
            <h1 class="section-label mx-0 mt-2 mb-2">
              Hébergement associé au forfait
            </h1>
          </b-col>
        </b-row>
        <b-row v-for="(value, index) in packageOption.room_ids" :key="index">
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Hébergement associé"
              rules="required"
              :vid="'Hébergement associé' + index"
            >
              <b-form-group
                label="Hébergement associé"
                label-for="accommodation-options"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  :id="'Hébergement associé' + index"
                  v-model="packageOption.room_ids[index].accommodation"
                  :options="accommodationOptions"
                  label="name"
                  :reduce="(packageOption) => packageOption.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="1" class="d-flex flex-column justify-content-center">
            <div>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="danger"
                size="sm"
                @click="removeAccommodationRoom(index)"
              >
                <feather-icon size="13" icon="TrashIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="addAccommodationToPackage(packageOption)"
            >
              + Ajouter un hébergement associé au forfait
            </b-button>
            <validation-provider
              #default="{ errors }"
              name="hébergement associé"
              rules="required"
            >
              <input
                type="hidden"
                v-model="packageOption.room_ids"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                <feather-icon
                  icon="AlertCircleIcon"
                  class="text-danger align-middle mr-25"
                />
                Il faut choisir un hébergement
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { useGroupApi } from "@/modules/group/composables/use-group-api";
import { reactive, computed } from "@vue/composition-api";
import Vue from "vue";
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BRow,
  BTab,
  BTabs,
  VBModal,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import store from "@/store";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";
import { indexOf } from "postcss-rtl/lib/affected-props";

export default {
  components: {
    BImg,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BCardText,
    BFormGroup,
    vSelect,
    BFormDatepicker,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTabs,
    BFormFile,
    BTab,
    BFormInvalidFeedback,
    ToastificationContent,
    BFormRadio,
  },
  name: "PackageInfoForm",
  directives: {
    Ripple,
  },
  props: {
    transportOptions: {
      type: Array,
    },
    accommodationOptions: {
      type: Array,
    },
    currentPackage: {
      type: Object,
    },
    pcVoyageEnable: {
      type: Boolean,
    },
  },
  setup(props) {
    const publicUrlEnabled =
      store.state[AUTH_STORE_NAMESPACE].orgSettings.public_url_enable;
    const { uploadImage } = useGroupApi();
    const { displayErrorMessage } = useToastNotification();

    let images = reactive({});

    let initialState = {
      name: null,
      image_url: null,
      is_public: true,
      reference_number: null,
      room_ids: [],
      occupation_types: [],
      accept_children: null,
      media_id: null,
      transportations: [],
    };

    const packageOption = reactive({...initialState})

    if (props.currentPackage.name !== undefined) {
      Object.assign(packageOption, props.currentPackage);

      packageOption.accept_children = props.currentPackage.accept_children;

      if (props.currentPackage.rooms.length > 0) {
        props.currentPackage.rooms.forEach((element) => {
          packageOption.room_ids.push({
            accommodation: element.id,
            checkin: null,
            checkout: null,
          });
        });
      }
      if (props.currentPackage.occupation_types.length > 0) {
        const occupationTypes = props.currentPackage.occupation_types.map(
          (x) => {
            return {
              type: x.type.toLowerCase(),
            }
          }
        );
        packageOption.occupation_types = occupationTypes;
      }
    }

    const uploadImageToPackage = async (event) => {
      let file = event.target.files[0];
      if (file) {
        let data = new FormData();
        data.append("name", file.name);
        data.append("type", "image");
        data.append("file", event.target.files[0]);

        try {
          let imageUrl = "";
          let response = await uploadImage(data);

          if (
            Object.hasOwn(response.data, "media_url") &&
            Object.hasOwn(response.data, "media_id")
          ) {
            imageUrl = response.data.media_url;
            Vue.set(packageOption, "media_id", response.data.media_id);
          } else {
            imageUrl = response.headers.location;
          }

          Vue.set(packageOption, "image_url", imageUrl);
        } catch (e) {
          console.error(e);
          displayErrorMessage("Le téléversement de l'image a échoué.");
          images.file1 = null;
          this.$refs["file-upload"].reset();
        }
      }
    };

    return {
      publicUrlEnabled,
      packageOption,
      images,
      uploadImageToPackage,
    };
  },
  computed: {
    flightTransportOptions() {
      return this.transportOptions
        .filter((x) => x.type === "flight")
        .map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
    },
    coachTransportOptions() {
      return this.transportOptions
        .filter((x) => x.type === "coach")
        .map((x) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
    },
    packageCoachTransportOptions() {
      return this.packageOption.transportations.filter(
        (x) => x.type === "coach"
      );
    },
    packageCoachTransportOption: {
      get() {
        const transportation = this.packageOption.transportations.find(
          (x) => x.type === "coach"
        );

        if (transportation) {
          return this.coachTransportOptions.find(
            (x) => x.value === transportation.id
          ).label;
        }
      },
      set(newValue) {
        const existingTransport = this.packageOption.transportations.find(
          (x) => x.type === "coach"
        );
        const newTransportOption = this.transportOptions.find(
          (x) => x.id === newValue.value
        );

        if (existingTransport) {
          const index = this.packageOption.transportations.findIndex(
            (x) => x.type === "coach"
          );
          this.packageOption.transportations.splice(
            index,
            1,
            newTransportOption
          );
        } else {
          this.packageOption.transportations.push(newTransportOption);
        }
      },
    },
    packageFlightTransportOption: {
      get() {
        const transportOption = this.packageOption.transportations.find(
          (x) => x.type === "flight"
        );

        if (transportOption) {
          return this.flightTransportOptions.find(
            (x) => x.value === transportOption.id
          ).label;
        }
      },
      set(newValue) {
        const existingTransport = this.packageOption.transportations.find(
          (x) => x.type === "flight"
        );
        const newTransportOption = this.transportOptions.find(
          (x) => x.id === newValue.value
        );

        if (existingTransport) {
          const index = this.packageOption.transportations.findIndex(
            (x) => x.type === "flight"
          );
          this.packageOption.transportations.splice(
            index,
            1,
            newTransportOption
          );
        } else {
          this.packageOption.transportations.push(newTransportOption);
        }
      },
    },
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
  },
  methods: {
    addAccommodationToPackage() {
      this.packageOption.room_ids.push({
        accommodation: null,
        checkin: null,
        checkout: null,
      });
    },
    removeAccommodationRoom(deleteIndex) {
      this.packageOption.room_ids.splice(deleteIndex, 1);
    },
  },
  watch: {
    currentPackage: function (newPackageOption, oldPackageOption) {
      if (newPackageOption.name !== undefined) {
        Object.assign(this.packageOption, newPackageOption)

        if (newPackageOption.rooms && newPackageOption.rooms.length > 0) {
          newPackageOption.rooms.forEach((element) => {
            this.packageOption.room_ids.push({
              accommodation: element.id,
              checkin: null,
              checkout: null,
            });
          });
        }

        if (newPackageOption.occupation_types && newPackageOption.occupation_types.length > 0) {
          newPackageOption.occupation_types.forEach((x) => {
            this.packageOption.occupation_types.push({
              type: x.type.type.toLowerCase()
            });
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
