<template>
  <div>
    <b-tabs v-model="tabIndex" class="mt-2" content-class="mt-2" justified>
      <b-tab title="1. Information du forfait">
        <group-package-info ref="package-info" v-bind:current-package="currentPackage" :action-type="actionType" :current-package-id="currentPackageId" :transport-options="currentTransportOptions" :accommodation-options="currentAccommodationOptions" @submitUpdatePackageInfo="updatePackage" @submitPackageInfo="createPackage" :key="basicInfoKey" @next-tab="tabIndex++"/>
      </b-tab>
      <b-tab title="2. Règles du forfait" :disabled="rulesTabLocked" >
         <GroupPackageRules v-if="currentPackage" ref="package-rules" :current-package="currentPackage" :action-type="actionType" @submitPackageRules="addPackageRules" @next-tab="tabIndex++" :has-package-rules="hasPackageRules" />
      </b-tab>
      <b-tab title="3. Prix du forfait" :disabled="pricingTabLocked" >
          <GroupPackagePricing v-if="currentPackage" ref="package-pricing" :current-package="currentPackage" :action-type="actionType" @submitPackagePricing="addPackagePricing" :key="packagePriceKey" @confirm-package="confirmPackage" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton } from 'bootstrap-vue'
import GroupPackageInfo from '@/views/groups/group/GroupPackages/GroupPackageInfo.vue'
import GroupPackageRules from '@/views/groups/group/GroupPackages/GroupPackageRules.vue'
import GroupPackagePricing from '@/views/groups/group/GroupPackages/GroupPackagePricing.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { GroupService } from '@/views/groups/services/GroupService.js'
export const groupService = new GroupService();
import { GroupPackagesService } from '@/views/groups/services/GroupPackagesService.js'
export const groupPackagesService = new GroupPackagesService();
import router from '@/router'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupPackageInfo,
    GroupPackageRules,
    GroupPackagePricing,
    ToastificationContent
  },
  data() {
    return {
      currentGroupId: null,
      currentPackageId: "123",
      currentPackage: null,
      currentTransportOptions: [],
      currentAccommodations: [],
      currentAccommodationOptions: [],
      packagePriceKey: 0,
      basicInfoKey: 0,
      tabIndex: 0,
      rulesTabLocked: true,
      pricingTabLocked: true,
      actionType: 'CREATE',
      hasPackageRules : false
    }
  },
    created: function () {
    this.currentGroupId = this.$route.params.id;
    this.currentPackageId = this.$route.params.package_id;

    if (this.$route.params.package_id) {
      this.actionType = 'EDIT';
      this.rulesTabLocked = false;
      this.pricingTabLocked = false;

    }

    return new Promise((resolve,reject) => {
        groupService.getTransportations(this.currentGroupId).then(response => {
          this.currentTransportOptions = response.data;
          return groupService.getAccommodations(this.currentGroupId)
        }).then(response => {
          this.currentAccommodationOptions = this.extractRoomsFromAccommodations(response.data);
          if  (this.$route.params.package_id) {
            return groupPackagesService.getPackage(this.currentGroupId, this.currentPackageId)
          } else {
            return {
              data: {}
            }
          }
        }).then(response => {

          this.currentPackage = response.data;
          this.basicInfoKey++;
          resolve(true)

        }).catch(error => {
          reject(true)
        })

    });
  },
  methods: {
    createPackage(payload) {
      this.currentPackage = payload;
      this.currentPackage = this.formatEmptyRules(this.currentPackage)
      this.rulesTabLocked=false;
      return new Promise((resolve, reject) => {
        groupPackagesService.createPackage(this.currentGroupId, payload).then(response => {
          let matches = (response.headers.location).split( "/" )
          this.currentPackageId = matches[7]
          this.$refs['package-info'].$refs['package-info-modal'].$refs['packageInfoModal'].hide();
          this.fetchCurrentPackage(this.currentPackageId);
          this.displayCreationConfirmation('Forfait sauvegardé avec succès',false);
          this.$refs['package-rules'].$forceUpdate();
          this.$refs['package-pricing'].$forceUpdate();
          resolve(true)
        }).catch(error => {
          console.error(error)
          this.displayCreationConfirmation(null,true)
          reject(true)
        })
      });

    },
    updatePackage(payload) {
      return new Promise((resolve, reject) => {
        groupPackagesService.updatePackage(this.currentGroupId, this.currentPackage.id,payload).then(response => {
          this.$refs['package-info'].$refs['package-info-modal'].$refs['packageInfoModal'].hide();
          this.displayCreationConfirmation('Forfait modifié avec succès',false);
          this.fetchCurrentPackage(this.currentPackage.id);
          resolve(true)
        }).catch(error => {
          this.displayCreationConfirmation(null,true)
          reject(true)
        })
      });

    },
    addPackageRules(updatedPackage) {
      this.pricingTabLocked = false;
      this.packagePriceKey += 1
      return new Promise((resolve, reject) => {
        groupPackagesService.addPackageRules(this.currentGroupId, this.currentPackageId, updatedPackage).then(response => {
          this.hasPackageRules = true;
          this.$refs['package-rules'].$refs['package-rules-modal'].$refs['packageRulesModal'].hide();
          this.displayCreationConfirmation('Règles du forfait sauvegardées avec succès',false);
          this.fetchCurrentPackage(this.currentPackage.id);
          this.$refs['package-rules'].$forceUpdate();
          this.$refs['package-pricing'].$forceUpdate();
          resolve(true)
        }).catch(error => {
          this.displayCreationConfirmation(null,true)
          reject(true)
        })
      });


    },
    addPackagePricing(updatedPackage) {
      return new Promise((resolve, reject) => {
        groupPackagesService.addPackagePricing(this.currentGroupId, this.currentPackageId, updatedPackage).then(response => {
          this.displayCreationConfirmation('Prix du forfait sauvegardé avec succès',false);
          this.fetchCurrentPackage(this.currentPackage.id);
          resolve(true)
        }).catch(error => {
          this.displayCreationConfirmation(null,true)
          reject(true)
        })
      });


    },
    extractRoomsFromAccommodations(accommodationsOptions) {
      let accommodationOptions = [];
      accommodationsOptions.forEach(accommodation => {
        accommodation.rooms.forEach(room => {
          accommodationOptions.push({
            name: `${room.room_category} - ${accommodation.title}`,
            id: room.id
          })
        })
      })

      return accommodationOptions;
    },
    formatEmptyRules(currentPkg) {
      currentPkg.occupation_types.forEach(occupation => {
        occupation.supported_children_types = [];
      })

      return currentPkg;
    },
    formatEmptyPricing(currentPkg) {
      const ADULT = 'ADULT'
      currentPkg.occupation_types.forEach(occupation => {
        if (occupation.supported_children_types.length>0){
          occupation.occupation_type_pricing = []
          let adult_index = 0;

          while (adult_index < occupation.adult_min) {
            occupation.occupation_type_pricing.push({
              "index": adult_index,
              "price": 0,
              "type": ADULT
            })
            adult_index++;
          }

          occupation.supported_children_types.forEach(children_category => {
            let children_cat_index = 0;
            let CHILDREN_TYPE = children_category.toUpperCase();
            let NB_OF_CHILDREN = (parseInt(occupation.travelers_max) - parseInt(occupation.adult_min))

            while (children_cat_index < NB_OF_CHILDREN) {
              occupation.occupation_type_pricing.push({
                "index": children_cat_index,
                "price": 0,
                "type": CHILDREN_TYPE
              })
              children_cat_index++;
            }
          })
        }else {
          occupation.occupation_type_pricing = []
          let adult_index = 0;

          while (adult_index < occupation.travelers_max) {
            occupation.occupation_type_pricing.push({
              "index": adult_index,
              "price": 0,
              "type": ADULT
            })
            adult_index++;
          }
        }


      })

      return currentPkg;
      /*
      if (!currentPkg.pricing) {
        currentPkg.pricing = {}
      }

      currentPkg.occupation_types.forEach(occupation => {


        if (!currentPkg.pricing[occupation]) {

          currentPkg.pricing[occupation] = {}
          currentPkg.pricing[occupation].adults = [];

          if (currentPkg.rules[occupation].children_categories) {
            currentPkg.rules[occupation].children_categories.forEach(children_category => {
              currentPkg.pricing[occupation][children_category] = [];
            })
          }

        }
      })
      */

    },
    displayCreationConfirmation(title,error) {
      let variant = 'success'

     if (error) {
      console.error(error)

        title = error.data.message
        variant = 'danger'
      }


      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'EditIcon',
          variant,
        },
      })
    },
    confirmPackage() {
      this.displayCreationConfirmation('Forfait sauvegardé avec succés',false);
      router.push(`/groups/${this.currentGroupId}/packages`)
    },
    fetchCurrentPackage(package_id){
      let currentGroupId = this.$route.params.id;
      groupPackagesService.getPackage(currentGroupId, package_id).then(response => {
        this.currentPackage = response.data;
      }).catch(error => {
      })
    }

  }
}
</script>

<style>

</style>
